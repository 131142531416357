<script setup lang="ts"></script>
<template>
  <v-card-title class="text-h5 font-weight-bold mt-6 mb-2 text-center"
    >PUCK MAN</v-card-title
  >
  <v-img height="200px" src="/images/labyrinth-doc-main.svg" />
  <v-card-text class="text-body-1 mt-3">
    Reach the blinking target by using the buttons to move around. Every move
    counts so be careful not to hit the hidden walls.
  </v-card-text>
</template>

export default [
  {
    name: "Current module in development",
    width: 1,
    height: 2,
    modules: ["timer", "wireMatcher"],
  },
  {
    name: "Beginner challenge 2x2",
    width: 2,
    height: 2,
    modules: ["timer", "simonSays", "numberDecoder", "labyrinth"],
  },
  {
    name: "Fidgety modules 2x2",
    width: 2,
    height: 2,
    modules: ["timer", "binaryLogic2", "morseCode", "wireMatcher"],
  },
  {
    name: "Test Morse code",
    width: 1,
    height: 2,
    modules: ["timer", "morseCode"],
  },
  {
    name: "Test Labyrinth",
    width: 1,
    height: 2,
    modules: ["timer", "labyrinth"],
  },
  {
    name: "Test Simon Says",
    width: 1,
    height: 2,
    modules: ["timer", "simonSays"],
  },
  {
    name: "Test Number decoder",
    width: 1,
    height: 2,
    modules: ["timer", "numberDecoder"],
  },
  {
    name: "Test Wire matcher",
    width: 1,
    height: 2,
    modules: ["timer", "wireMatcher"],
  },
  {
    name: "Test Binary Logic ADVANCED",
    width: 1,
    height: 2,
    modules: ["timer", "binaryLogic"],
  },
  {
    name: "Test Binary Logic",
    width: 1,
    height: 2,
    modules: ["timer", "binaryLogic2"],
  },
  {
    name: "All modules",
    width: 3,
    height: 3,
    modules: [
      "labyrinth",
      "numberDecoder",
      "timer",
      "simonSays",
      "wireMatcher",
      "morseCode",
      "binaryLogic2",
      "labyrinth",
      "numberDecoder",
    ],
  },
];

<script setup lang="ts"></script>
<template>
  <svg
    class="timer-slanted-border"
    width="418"
    height="297"
    viewBox="0 0 418 297"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 63.608V41.332L41.1586 0H63.3412L47.5676 15.84H29.5755C24.1308 15.84 19.717 20.2723 19.717 25.74V43.808L0 63.608Z"
      fill="white"
    />
    <path
      d="M9.85849 0H21.9355L0 22.028V9.9C0 4.43228 4.41382 0 9.85849 0Z"
      fill="white"
    />
    <path
      d="M82.5644 0L66.7907 15.84H88.9732L104.747 0H82.5644Z"
      fill="white"
    />
    <path
      d="M108.196 15.84L123.97 0H146.152L130.379 15.84H108.196Z"
      fill="white"
    />
    <path
      d="M165.376 0L149.602 15.84H171.785L187.558 0H165.376Z"
      fill="white"
    />
    <path
      d="M191.008 15.84L206.781 0H228.964L213.19 15.84H191.008Z"
      fill="white"
    />
    <path d="M248.187 0L232.413 15.84H254.596L270.37 0H248.187Z" fill="white" />
    <path
      d="M273.819 15.84L289.593 0H311.775L296.002 15.84H273.819Z"
      fill="white"
    />
    <path
      d="M330.998 0L315.225 15.84H337.407L353.181 0H330.998Z"
      fill="white"
    />
    <path
      d="M356.63 15.84L372.404 0H394.586L378.813 15.84H356.63Z"
      fill="white"
    />
    <path
      d="M412.697 1.11786L395.738 18.1477C397.896 19.9639 399.269 22.691 399.269 25.74V36.878L418 18.068V9.9C418 6.08236 415.848 2.76987 412.697 1.11786Z"
      fill="white"
    />
    <path
      d="M399.269 56.182L418 37.372V59.648L399.269 78.458V56.182Z"
      fill="white"
    />
    <path
      d="M418 78.952L399.269 97.762V120.038L418 101.228V78.952Z"
      fill="white"
    />
    <path
      d="M399.269 139.342L418 120.532V142.808L399.269 161.618V139.342Z"
      fill="white"
    />
    <path
      d="M418 162.112L399.269 180.922V203.198L418 184.388V162.112Z"
      fill="white"
    />
    <path
      d="M399.269 222.502L418 203.692V225.968L399.269 244.778V222.502Z"
      fill="white"
    />
    <path
      d="M418 245.272L399.269 264.082V269.28C399.269 274.748 394.855 279.18 389.41 279.18H384.234L366.489 297H388.671L418 267.548V245.272Z"
      fill="white"
    />
    <path
      d="M407.895 297L418 286.852V287.1C418 292.568 413.586 297 408.142 297H407.895Z"
      fill="white"
    />
    <path
      d="M347.266 297L365.011 279.18H342.828L325.083 297H347.266Z"
      fill="white"
    />
    <path
      d="M323.605 279.18L305.86 297H303C303 297 301.36 287.412 305.86 282.05C310.36 276.688 323.605 279.18 323.605 279.18Z"
      fill="white"
    />
    <path
      d="M103.5 282.05C106 285.04 106 289.802 106 289.802L98.8317 297H76.6492L94.3944 279.18C94.3944 279.18 101 279.06 103.5 282.05Z"
      fill="white"
    />
    <path
      d="M75.1714 279.18L57.4261 297H35.2435L52.9888 279.18H75.1714Z"
      fill="white"
    />
    <path
      d="M16.0204 297L33.7657 279.18H29.5755C24.6743 279.18 20.6084 275.588 19.8455 270.883L0.522168 290.288C1.84341 294.191 5.52434 297 9.85849 297H16.0204Z"
      fill="white"
    />
    <path
      d="M19.717 251.708L0 271.508V249.232L19.717 229.432V251.708Z"
      fill="white"
    />
    <path
      d="M0 229.928L19.717 210.128V187.852L0 207.652V229.928Z"
      fill="white"
    />
    <path
      d="M19.717 168.548L0 188.348V166.072L19.717 146.272V168.548Z"
      fill="white"
    />
    <path
      d="M0 146.768L19.717 126.968V104.692L0 124.492V146.768Z"
      fill="white"
    />
    <path
      d="M19.717 85.388L0 105.188V82.912L19.717 63.112V85.388Z"
      fill="white"
    />
  </svg>
</template>

import {
  _B,
  _G,
  _R,
  _Y,
  SimonSaysPatternStructure,
} from "@src/modules/SimonSays/structures/SimonSaysPatternStructure";

//prettier-ignore
export default [
    new SimonSaysPatternStructure(_R, [_G, _R, _Y, _B, _B]),
    new SimonSaysPatternStructure(_G, [_G, _R, _Y, _B, _Y]),
    new SimonSaysPatternStructure(_B, [_G, _R, _Y, _B, _R]),
    new SimonSaysPatternStructure(_Y, [_G, _R, _Y, _B, _G]),
    new SimonSaysPatternStructure(_G, [_G, _R, _Y, _B, _Y]),
    new SimonSaysPatternStructure(_B, [_G, _R, _Y, _B, _R]),
    new SimonSaysPatternStructure(_Y, [_G, _R, _Y, _B, _G]),
    new SimonSaysPatternStructure(_Y, [_G, _R, _Y, _Y, _R]),
    new SimonSaysPatternStructure(_R, [_G, _R, _Y, _Y, _G]),
    new SimonSaysPatternStructure(_G, [_G, _R, _Y, _R, _G]),
    new SimonSaysPatternStructure(_R, [_G, _R, _B, _B, _Y]),
    new SimonSaysPatternStructure(_G, [_G, _R, _B, _B, _R]),
    new SimonSaysPatternStructure(_B, [_G, _R, _B, _B, _G]),
    new SimonSaysPatternStructure(_B, [_G, _R, _B, _Y, _R]),
    new SimonSaysPatternStructure(_Y, [_G, _R, _B, _Y, _G]),
    new SimonSaysPatternStructure(_R, [_G, _R, _B, _R, _G]),
    new SimonSaysPatternStructure(_B, [_G, _R, _B, _Y, _R]),
    new SimonSaysPatternStructure(_Y, [_G, _R, _B, _Y, _G]),
    new SimonSaysPatternStructure(_R, [_G, _R, _B, _R, _G]),
    new SimonSaysPatternStructure(_G, [_G, _R, _Y, _R, _G]),
    new SimonSaysPatternStructure(_Y, [_G, _Y, _B, _B, _Y]),
    new SimonSaysPatternStructure(_R, [_G, _Y, _B, _B, _R]),
    new SimonSaysPatternStructure(_G, [_G, _Y, _B, _B, _G]),
    new SimonSaysPatternStructure(_G, [_G, _Y, _B, _Y, _R]),
    new SimonSaysPatternStructure(_B, [_G, _Y, _B, _Y, _G]),
    new SimonSaysPatternStructure(_Y, [_G, _Y, _B, _R, _G]),
    new SimonSaysPatternStructure(_G, [_G, _Y, _B, _Y, _R]),
    new SimonSaysPatternStructure(_B, [_G, _Y, _B, _Y, _G]),
    new SimonSaysPatternStructure(_Y, [_G, _Y, _B, _R, _G]),
    new SimonSaysPatternStructure(_R, [_G, _Y, _Y, _R, _G]),
    new SimonSaysPatternStructure(_R, [_G, _B, _B, _Y, _R]),
    new SimonSaysPatternStructure(_G, [_G, _B, _B, _Y, _G]),
    new SimonSaysPatternStructure(_B, [_G, _B, _B, _R, _G]),
    new SimonSaysPatternStructure(_Y, [_G, _B, _Y, _R, _G]),
    new SimonSaysPatternStructure(_Y, [_G, _B, _Y, _R, _G]),
    new SimonSaysPatternStructure(_B, [_R, _Y, _B, _B, _Y]),
    new SimonSaysPatternStructure(_Y, [_R, _Y, _B, _B, _R]),
    new SimonSaysPatternStructure(_R, [_R, _Y, _B, _B, _G]),
    new SimonSaysPatternStructure(_R, [_R, _Y, _B, _Y, _R]),
    new SimonSaysPatternStructure(_G, [_R, _Y, _B, _Y, _G]),
    new SimonSaysPatternStructure(_B, [_R, _Y, _B, _R, _G]),
    new SimonSaysPatternStructure(_R, [_R, _Y, _B, _Y, _R]),
    new SimonSaysPatternStructure(_G, [_R, _Y, _B, _Y, _G]),
    new SimonSaysPatternStructure(_B, [_R, _Y, _B, _R, _G]),
    new SimonSaysPatternStructure(_Y, [_R, _Y, _Y, _R, _G]),
    new SimonSaysPatternStructure(_Y, [_R, _B, _B, _Y, _R]),
    new SimonSaysPatternStructure(_R, [_R, _B, _B, _Y, _G]),
    new SimonSaysPatternStructure(_G, [_R, _B, _B, _R, _G]),
    new SimonSaysPatternStructure(_B, [_R, _B, _Y, _R, _G]),
    new SimonSaysPatternStructure(_B, [_R, _B, _Y, _R, _G]),
    new SimonSaysPatternStructure(_B, [_Y, _B, _B, _Y, _R]),
    new SimonSaysPatternStructure(_Y, [_Y, _B, _B, _Y, _G]),
    new SimonSaysPatternStructure(_R, [_Y, _B, _B, _R, _G]),
    new SimonSaysPatternStructure(_G, [_Y, _B, _Y, _R, _G]),
    new SimonSaysPatternStructure(_G, [_Y, _B, _Y, _R, _G]),
    new SimonSaysPatternStructure(_R, [_B, _B, _Y, _R, _G]),
];
